import DishCard from "../components/DishCard";
import MenuHeader from "../components/MenuHeader";
import CategoryBar from "../components/CategoryBar";
import SimpleBackdrop from "../components/SimpleBackdrop";
import SessionService from "../services/SessionService";
import RequestService from "../services/RequestService";
import BottomNavbar from "../components/BottomNavbar";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PaypalComponent from "../components/PaypalComponent";
import { Toolbar } from "@mui/material";
import "../css/MenuPage.css";
import config from "../Config";
import GetSize from "../utils/GetSize";
import axios from "axios";

function MenuPage() {
  const { _sessionId } = useParams();
  const [tableNumber, setTableNumber] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  const [numOfCategorys, setNumOfCategory] = useState(0);
  const [idx, setIdx] = useState(0);
  const [currCategory, setCurrCategory] = useState({});
  const [dishData, setDishData] = useState([]);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const _height = GetSize().height;

  const url =
    "/api/client/getMenus.aspx?package={%22key%22:%22j1RpMum08UKS1oT7r7E1bA%22,%22profileGuid%22:%22client%22,%22itemGuid%22:%22default%22,%22endpoint%22:%220.0.0.0%22,%22target%22:%22CategoryItem%22,%22package%22:%22%22,%22filters%22:[{%22key%22:%22guid%22,%22value%22:%22487b8e2106b546f7b408f850d11e23ef%22,%22condition%22:%22eq%22}]}";

  const requestRestaurant = (restaurantId) => {
    RequestService.getItemDataByGuid("Restaurant", restaurantId).then(
      (response) => {
        setRestaurantName(response[0].restaurantName);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(JSON.stringify(resMessage));
      }
    );
  };

  const requestTable = (tableId) => {
    RequestService.getItemDataByGuid("Table", tableId).then(
      (response) => {
        setTableNumber(response[0].tableNumber);
        setBackdropOpen(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(JSON.stringify(resMessage));
      }
    );
  };

  useEffect(() => {
    setBackdropOpen(true);
    SessionService.getSessionContentBySessionId(_sessionId).then(
      (response) => {
        const restaurantId = response[0];
        const tableId = response[1];

        requestRestaurant(restaurantId);
        requestTable(tableId);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(JSON.stringify(resMessage));
      }
    );
  }, [_sessionId]);

  useEffect(() => {
    axios.get(url).then((response) => {
      const categorys = response.data.package[0].categorys;
      setCurrCategory(categorys[idx]);
      setNumOfCategory(categorys.length);

      const currDishesId = response.data.package[0].categorys[idx].dishes;
      const promises = [];
      for (const dishId of currDishesId) {
        const dishURL = `/api/client/getDishes.aspx?package={"key":"j1RpMum08UKS1oT7r7E1bA","profileGuid":"client","itemGuid":"default","endpoint":"0.0.0.0","target":"CategoryItem","package":"","filters":[{"key":"guid","value":\"${dishId}\","condition":"eq"}]}`;
        promises.push(axios.get(dishURL));
      }
      Promise.all(promises).then((responses) => {
        const dishData = responses.map((response) => response.data.package[0]);
        setDishData(dishData);
      });
    });
  }, [idx]);
  const saveListToLocalStorage = (cartitems) => {
    localStorage.setItem('cartitems', JSON.stringify(cartitems));
  };
  const loadListFromLocalStorage = () => {
    const storedList = localStorage.getItem('cartitems');
    if (storedList) {
      return JSON.parse(storedList);
    }
  };
  const onAdd = (dish) => {
    let guid = dish.guid;
    let cartItems = loadListFromLocalStorage();
    let exist = null;
    if(cartItems){
      exist = cartItems.find((x) => x.guid === guid);
    }
    let new_cart_list = null;
    if (exist){
      new_cart_list = cartItems.map((x) =>{
        if(x.guid === guid ){
          let copy_x = JSON.parse(JSON.stringify(x));
          copy_x.qty = parseInt(copy_x.qty) + 1;
          return copy_x;
        }
        return JSON.parse(JSON.stringify(x));
      });
    }else {
      let product =  dish;
      if(cartItems){
        new_cart_list = [...cartItems, { ...product, qty: 1 }];
      }else{
        new_cart_list = [{ ...product, qty: 1 }];
      }
    }
    saveListToLocalStorage(new_cart_list);
  };
  const [dishCount, setDishCount] = useState(0);
  const [totalMoney, setTotoalMoney] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [orderDetail, setOrderDetail] = useState([]);

  const handleAddDish = (dish) => {

    setDishCount(dishCount + 1);
    setTotoalMoney(totalMoney + Number(dish.servePrice));
    setOrderDetail([...orderDetail, dish]);
    setShowMessage(true);
    onAdd(dish);
    console.log(loadListFromLocalStorage());
    setTimeout(() => setShowMessage(false), 1000);
  };

  const dishes = dishData.map((item) => {
    return (
      <DishCard key={item.guid} item={item} onAdd={() => handleAddDish(item)} />
    );
  });

  const handlePrevCategory = () => {
    if (idx > 0) {
      setIdx(idx - 1);
    }
  };

  const handleNextCategory = () => {
    if (idx < numOfCategorys - 1) {
      setIdx(idx + 1);
    }
  };
  return (
    <div className="MenuPage" style={{height: _height}}>
      <SimpleBackdrop open={backdropOpen} setOpen={setBackdropOpen} />
      <MenuHeader
        _sessionId = {_sessionId}
        restaurntName={restaurantName}
        tableId={tableNumber}
        cartTotal={dishCount}
      />
      <CategoryBar
        categoryName={currCategory.categoryItemName}
        handlePrevCategory={handlePrevCategory}
        handleNextCategory={handleNextCategory}
      />
      <div className="dishes" style={{height: _height}}>{dishes}</div>
      {showMessage && (
        <div
          style={{
            backgroundColor: "lightgreen",
            color: "black",
            paddingTop: "6px",
            paddingBottom: "6px",
            paddingLeft: "16px",
            paddingRight: "16px",
            fontWeight: 600,
            borderRadius: "10px",
            position: "absolute",
            top: "400px",
          }}
        >
          Added to cart successfully!
        </div>
      )}
      <Toolbar id="back-to-top-anchor" />
      <BottomNavbar
        _leftContent={
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, fontWeight: 700, fontSize: "1rem" }}
          >
            Total {config().currency_symbol}{totalMoney}
          </Typography>
        }
        _rightContent={
        <PaypalComponent _amount={totalMoney.toString()} />

        }
      />
    </div>
  );
}

export default MenuPage;
