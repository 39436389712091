import Config from "../Config";

const KEY = Config().dev_key;

function GenAddPackage(_profileGuid, _target, _package) {
  const data = {
    key: KEY,
    profileGuid: _profileGuid,
    itemGuid: "default",
    endpoint: "0.0.0.0",
    target: _target,
    package: GenPackageData(_package),
    filters: []
  };

  return JSON.stringify(data);
}

function GenGetByGuidPackage(_profileGuid, _target, _guid) {
  const data = {
    key: KEY,
    profileGuid: _profileGuid,
    itemGuid: _guid,
    endpoint: "0.0.0.0",
    target: _target,
    package: GenPackageData({}),
    filters: []
  };

  return JSON.stringify(data);
}

function GenGetPackage(_profileGuid, _target, _keys, _values) {
  const data = {
    key: KEY,
    profileGuid: _profileGuid,
    itemGuid: "default",
    endpoint: "0.0.0.0",
    target: _target,
    package: GenPackageData({}),
    filters: GenFilter(_keys, _values)
  };

  return JSON.stringify(data);
}

function GenDeletePackage(_profileGuid, _target, _guid) {
  const data = {
    key: KEY,
    profileGuid: _profileGuid,
    itemGuid: _guid,
    endpoint: "0.0.0.0",
    target: _target,
    package: GenPackageData({}),
    filters: []
  };

  return JSON.stringify(data);
}

function GenModifyPackage(_profileGuid, _target, _package, _guid) {
  const data = {
    key: KEY,
    profileGuid: _profileGuid,
    itemGuid: "default",
    endpoint: "0.0.0.0",
    target: _target,
    package: GenPackageData(_package, _guid),
    filters: []
  };

  return JSON.stringify(data);
}

function GenGetSessionPackage(_profileGuid, _sessionId,) {
  const data = {
    key: KEY,
    profileGuid: _profileGuid,
    itemGuid: _sessionId,
    endpoint: "0.0.0.0",
    target: "MySession",
    package: GenPackageData({}),
    filters: []
  };

  return JSON.stringify(data);
}

function GenFilter(_keys, _values) {
  var data = [{key: "status", value: Config().status}];
  try {
    for(var i = 0; i < _keys.length; i++) {
      var temp = {key: _keys[i], value: _values[i]};
      data = [...data, temp];
    }
  } catch (err) {
    console.log(err)
  }
  
  return data;
}

function GenPackageData(_package, _guid = "") {

  if(_guid !== "") {
    const IModel = {guid: _guid, status: Config().status, created: "", updated: ""};
    return JSON.stringify({...IModel, ..._package})
  }
  return JSON.stringify(_package)
  
}

export { GenAddPackage, GenGetByGuidPackage, GenGetPackage, GenDeletePackage, GenModifyPackage, GenGetSessionPackage };
