import { css } from "styled-components";

export const ValignTextMiddle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InterNormalBlack20px = css`
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl);
  font-weight: 400;
  font-style: normal;
`;

export const InterBoldBlack14px = css`
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
`;

export const InterBoldBlack16px = css`
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
`;

export const InterNormalBlack13px = css`
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 100;
  font-style: normal;
`;
export const InterNormalBlack8px = css`
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 100;
  font-style: normal;
`;
