import React, { useEffect, useState } from "react";
import "../css/WelcomePage.css";
import "../css/Button.css";
import { useParams, useHistory } from "react-router-dom";
import RequestService from "../services/RequestService";
import Toolbar from "@mui/material/Toolbar";
import PopUpWindow from "../components/PopUpWindow";
import HeaderNavbar from "../components/HeaderNavbar";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { terms } from "../resources/text/terms";
import SimpleBackdrop from "../components/SimpleBackdrop";
import GetSize from "../utils/GetSize";
import SessionService from "../services/SessionService";

function WelcomePage() {
  const history = useHistory();
  const { _sessionId } = useParams();
  const [tableNumber, setTableNumber] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  const [dialogEnable, setdialogEnable] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [notOpenWithQrCode, setNotOpenWithQrCode] = useState(false);
  const _height = GetSize().height;

  const requestRestaurant = (restaurantId) => {
    RequestService.getItemDataByGuid("Restaurant", restaurantId).then(
      (response) => {
        setRestaurantName(response[0].restaurantName);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(JSON.stringify(resMessage));
      }
    );
  }

  const requestTable = (tableId) => {
    RequestService.getItemDataByGuid("Table", tableId).then(
      (response) => {
        setTableNumber(response[0].tableNumber);
        setBackdropOpen(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(JSON.stringify(resMessage));
      }
    );
  }

  useEffect(() => {
    if (_sessionId === undefined) {
      setNotOpenWithQrCode(true);
    } else {
      setBackdropOpen(true);
      SessionService.getSessionContentBySessionId(_sessionId).then(
        (response) => {
          const restaurantId = response[0];
          const tableId = response[1];

          requestRestaurant(restaurantId);
          requestTable(tableId);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(JSON.stringify(resMessage));
        }
      );
    }
  }, [_sessionId]);

  const handleOpenClick = () => {
    setdialogEnable(true);
  };

  const handleButtonClick = () => {
    history.push("/order/" + _sessionId);
  };

  return (
    <div className="container">
      <SimpleBackdrop open={backdropOpen} setOpen={setBackdropOpen} />
      <div style={{ height: _height }} className="backgroundContainer" />
      <HeaderNavbar
        _content={
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <b>{restaurantName}</b>
          </Typography>
        }
      />
      <div style={{ height: _height / 2 }} className="homeContainer">
        <Toolbar />
        <br />
        <br />
        <p className="typoStyle">Welcome, your table is below.</p>
        <br />
        <p className="tableTypoStyle">Table {tableNumber}</p>
        <Button
          className={`brownButton buttonPos`}
          variant="contained"
          onClick={handleButtonClick}
        >
          Continue
        </Button>
      </div>
      <div style={{ height: _height / 2 }} className="termContainer">
        <p style={{ fontSize: "0.1875rem" }}>
          By clicking the continue button, I hereby agree to and accept{" "}
          <span className="termStyle" onClick={handleOpenClick}>
            <u>terms and conditions</u>
          </span>
        </p>
      </div>
      <PopUpWindow
        dialogEnable={dialogEnable}
        setdialogEnable={setdialogEnable}
        _title={"Terms and conditions"}
        _content={terms}
        _buttonContent={"Confirm"}
        _action={true}
      />
      <PopUpWindow
        dialogEnable={notOpenWithQrCode}
        _title={"Message"}
        _content={"Please scan QR code to access."}
        _action={false}
      />
      <Toolbar id="back-to-top-anchor" />
    </div>
  );
}

export default WelcomePage;
