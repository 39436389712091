import AppBar from "@mui/material/AppBar";
import { Toolbar } from "@mui/material";

function BottomNavbar({_leftContent, _rightContent}) {
  

  return (
    <AppBar
      position="fixed"
      color=""
      sx={{ top: "auto", bottom: 0, boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.1)" }}
    >
      <Toolbar>
        {_leftContent}
        {_rightContent}
      </Toolbar>
    </AppBar>
  );
}

export default BottomNavbar;
