import axios from "axios";
import Config from "../Config";
import {GenAddPackage, GenGetByGuidPackage, GenDeletePackage, GenGetPackage, GenModifyPackage} from "./genPackage";
const PROFILE_GUID = "client";
const API_URL = Config().url + "/" + PROFILE_GUID + "/";
axios.defaults.withCredentials=true


class RequestService {
  
  postItemData(_target, _package) {
    return axios.post(
      API_URL + "Add" + _target + ".aspx?package=" + GenAddPackage(PROFILE_GUID, _target + "Item", _package)
    );
  }
  getItemDataByGuid(_target, _guid) {
    return axios.get(API_URL + "get" + _target + "ByGuid.aspx?package=" + GenGetByGuidPackage(PROFILE_GUID, _target + "Item", _guid)).then((response) => {
      if (response.data) {
        localStorage.setItem("current" + _target + "Data", JSON.stringify(response.data.package));
      }
      return response.data.package;
    });
  }

  getItemDataByRestaurantId(_target, _keys, _values) {
    return axios.get(API_URL + "get" + _target + "s.aspx?package=" + GenGetPackage(PROFILE_GUID, _target + "Item", _keys, _values)).then((response) => {
      if (response.data) {
        localStorage.setItem(_target + "ItemData", JSON.stringify(response.data.package));
      }
      return response.data.package;
    });
  }

  getCurrentItemData(_target) {
    return JSON.parse(localStorage.getItem(_target + "ItemData"));
  }

  getSingleItemData(_target) {
    return JSON.parse(localStorage.getItem("current" + _target + "Data"))
  }

  deleteItemDataByGuid(_target, _guid) {
    return axios.post(API_URL + "delete" + _target + "ByGuid.aspx?package=" + GenDeletePackage(PROFILE_GUID, _target + "Item", _guid));
  }

  modifyItemDataByGuid(_target, _package, _guid) {
    return axios.post(API_URL + "modify" + _target + ".aspx?package=" + GenModifyPackage(PROFILE_GUID, _target + "Item", _package, _guid));
  }


}

export default new RequestService();
