export default function config() {
    return {
        //url: "https://clickity.darkspede.space/api",
        // with proxy
        url: "/api",
        dev_key: "j1RpMum08UKS1oT7r7E1bA",
        //paypal
        client_id: "AXcW-eArY80pZQMM1VmwSm_yaYDjIZ4v6jWSu1quQz6aBYL52WbqfDQcYo18aHaCHzvaSrJYTZllst4L",
        status: "dev",
        currency: "AUD",
        currency_symbol: '$'
    };
}