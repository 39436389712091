import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "../css/DishCard.css"
import config from "../Config";
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPepperHot,
  faLeaf,
  faChampagneGlasses,
} from "@fortawesome/free-solid-svg-icons"; */

export default function DishCard(props) {

  const textColor = {
    baseColor: "black",
    themeColor: "#904B40",
  };

  return (
    <Card sx={{ maxWidth: 345, marginBottom: "16px" }}>
      <Grid container>
        <Grid item xs={12}>
          <CardMedia
            style={{ padding: "0px" }}
            className="dishImg"
            component="img"
            alt="green iguana"
            height="140"
            image={props.item.imageUrl}
            referrerPolicy="no-referrer"
          />
        </Grid>

        <Grid item xs={12}>
          <CardContent style={{ padding: "14px" }}>
            <Typography
              gutterBottom
              variant="h5"
              component="span"
              style={{
                alignItems: "center",
                justifyContent: "center",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              <span
                className="name"
                style={{ color: textColor.baseColor, paddingRight: "4px" }}
              >
                {props.item.dishItemName}
              </span>
              <span
                className="originalName"
                style={{
                  color: textColor.themeColor,
                  paddingRight: "4px",
                }}
              >
                {props.item.originalName}
              </span>
              {/* <span style={{ fontSize: "16px" }}>
                <FontAwesomeIcon
                  icon={faPepperHot}
                  style={{ color: "red", paddingRight: "4px" }}
                />
                <FontAwesomeIcon
                  icon={faLeaf}
                  style={{ color: "green", paddingRight: "4px" }}
                />
                <FontAwesomeIcon
                  icon={faChampagneGlasses}
                  style={{ color: "blue", paddingRight: "4px" }}
                />
              </span> */}
            </Typography>
            <div>
              <Grid
                container
                direction="row"
                alignItems="flex-end"
                justify="center"
              >
                <Grid
                  item
                  className="dishDetail"
                  style={{ fontSize: "14px", fontWeight: 400 }}
                >
                  {props.item.ingredient} {props.item.unitServe}
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justify="center"
                  spacing={0.5}
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <Grid
                    item
                    className="dishPrice"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: textColor.baseColor,
                    }}
                  >
                    {config().currency_symbol}{props.item.servePrice}
                  </Grid>
                  <Grid item className="addIcon">
                    <AddCircleIcon
                      style={{
                        color: "#904B40",
                        fontSize: "1.75rem",
                        display: "inline-flex",
                        paddingTop: "5px",
                        cursor: "pointer",
                      }}
                      onClick={props.onAdd}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}
