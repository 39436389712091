import {useCallback,useState,useEffect} from 'react';
 
 export default function ChangeSize(){
    const [size,setSize] = useState({
            width:document.documentElement.clientWidth || window.innerWidth || document.body.clientWidth,
            height:document.documentElement.clientHeight || window.innerHeight || document.body.innerHeight
    })

    const onResize = useCallback(()=>{
        setSize({
            width:document.documentElement.clientWidth || window.innerWidth || document.body.clientWidth,
            height:document.documentElement.clientHeight || window.innerHeight || document.body.innerHeight
        })
    },[])
 
    useEffect(()=>{
        window.addEventListener('resize',onResize);
        return (()=>{
            window.removeEventListener('resize',onResize)
        })
    },[onResize])
 
    return size;
}