import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import "../css/HeaderNavbar.css";
import { ThemeProvider, createTheme } from "@mui/material";

function HeaderNavbar({_content}) {

  const customTheme = createTheme({
    palette: {
      gray: {
        main: "#efefef",
      },
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <AppBar component="nav" color="gray" className="customisestyle">
        <Toolbar>
          {_content}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default HeaderNavbar;
