import axios from "axios";
import Config from "../Config";
import {GenGetSessionPackage} from "./genPackage";
const PROFILE_GUID = "client";
const API_URL = Config().url + "/" + PROFILE_GUID + "/";
axios.defaults.withCredentials=true

class SessionService {

    getSessionContentBySessionId(_sessionId) {
    return axios.get(API_URL + "GetSessionContentBySessionId.aspx?package=" + GenGetSessionPackage(PROFILE_GUID, _sessionId)).then((response) => {
      return response.data.package[0].content;
    });
  }


}

export default new SessionService();