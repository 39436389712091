import React from "react";
import "../css/CategoryBar.css"
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

function CategoryBar({ categoryName, handlePrevCategory, handleNextCategory }) {

  return (
    <div className="categoryRoot">
      <Button
        className="button"
        onClick={handlePrevCategory}
        style={{
          fontWeight: 700,
          fontSize: 20,
          marginLight: "12px",
          textAlign: "center",
          margin: 0,
          padding: 0,
          color: "#904B40",
        }}
      >
        &lt;
      </Button>
      <Typography
        variant="h6"
        style={{
          fontWeight: 600,
          fontSize: 20,
          backgroundColor: "#904B40",
          flexGrow: 1,
          marginLeft: "10px",
          marginRight: "10px",
          textAlign: "center",
          color: "white",
          borderRadius: "5px",
        }}
      >
        {categoryName}
      </Typography>
      <Button
        className="button"
        onClick={handleNextCategory}
        style={{
          fontWeight: 700,
          fontSize: 20,
          marginRight: "12px",
          textAlign: "center",
          margin: 0,
          padding: 0,
          color: "#904B40",
        }}
      >
        &gt;
      </Button>
    </div>
  );
}

export default CategoryBar;
