import * as React from "react";
import "../css/PopUpWindow.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function PopUpWindow({ dialogEnable, setdialogEnable, _title, _content, _buttonContent, _action }) {

  const handleCloseClick = () => {
    setdialogEnable(false);
  };

  return (
    <div>
      <Dialog open={dialogEnable}>
        <DialogTitle>{_title}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText tabIndex={-1}>
            {_content}
          </DialogContentText>
        </DialogContent>
        {_action ? <DialogActions className="centerContent">
          <Button onClick={handleCloseClick}>
            {_buttonContent}
          </Button>
        </DialogActions> : null}
      </Dialog>
    </div>
  );
}

export default PopUpWindow;
