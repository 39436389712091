import CartPage from "../components/CartPageComponents/CartPage";
import SubTotal from "../components/CartPageComponents/SubTotal";
import React, {useState} from "react";
import {Toolbar, Typography} from "@mui/material";
import DishCard from "../components/DishCard";
import MenuHeader from "../components/MenuHeader";
import CategoryBar from "../components/CategoryBar";
import SimpleBackdrop from "../components/SimpleBackdrop";
import SessionService from "../services/SessionService";
import RequestService from "../services/RequestService";
import BottomNavbar from "../components/BottomNavbar";
import {useHistory, useParams} from "react-router-dom";
import PaypalComponent from "../components/PaypalComponent";
import "../css/MenuPage.css";
import config from "../Config";
import GetSize from "../utils/GetSize";
import axios from "axios";
import HeaderNavbar from "../components/HeaderNavbar";
import Button from "@mui/material/Button";

export default function CartPagePage(){
    const history = useHistory();
    const { _sessionId } = useParams();
    const handleButtonClick = () => {
        history.push("/order/" + _sessionId);
    };
    const saveListToLocalStorage = (cartitems) => {
        localStorage.setItem('cartitems', JSON.stringify(cartitems));
    };
    const loadListFromLocalStorage = () => {
        const storedList = localStorage.getItem('cartitems');
        if (storedList) {
            return JSON.parse(storedList);
        }
    };
    const [cartItems, setCartItems] = useState(loadListFromLocalStorage());
    let cartItemsCopy = loadListFromLocalStorage();
    const totalPrice = (cartItemsCopy)=>{
        if(cartItemsCopy){
            let total = 0;
            cartItemsCopy.map((item)=>{
                if(item.qty){
                    // console.log(item);
                    // console.log(item.servePrice);
                    // console.log(parseFloat(item.servePrice));
                    total = total + parseInt(item.qty) * parseFloat(item.servePrice);
                }
            });
            return total;
        }
        return 0;
    }
    const total = totalPrice(cartItemsCopy);
    const [price,setPrice]   =useState(total);
    const onAdd = (guid) => {
        const exist = cartItemsCopy.find((x) => x.guid === guid);
        let new_cart_list = null;
        if (exist){
            new_cart_list = cartItemsCopy.map((x) =>{
                if(x.guid === guid ){
                    let copy_x = JSON.parse(JSON.stringify(x));
                    copy_x.qty = parseInt(copy_x.qty) + 1;
                    return copy_x;
                }
                return JSON.parse(JSON.stringify(x));
            });
        }else {
            let product =  cartItemsCopy.find((x) => x.guid === guid);
            new_cart_list = [...cartItemsCopy, { ...product, qty: 1 }];
        }
        saveListToLocalStorage(new_cart_list);
        cartItemsCopy = loadListFromLocalStorage();
        setCartItems(new_cart_list);
        setPrice(totalPrice(new_cart_list));
    };
    const onRemove = (guid) => {
        const exist = cartItemsCopy.find((x) => x.guid === guid);
        let new_cart_list = null;
        if (exist.qty === 1) {
            new_cart_list = cartItemsCopy.filter(x=>x.guid !== guid);
        } else {
            new_cart_list = cartItemsCopy.map((x) =>{
                if(x.guid === guid ){
                    let copy_x = JSON.parse(JSON.stringify(x));
                    copy_x.qty = parseInt(copy_x.qty) - 1;
                    return copy_x;
                }
                return JSON.parse(JSON.stringify(x));
            });
        }
        saveListToLocalStorage(new_cart_list);
        cartItemsCopy = loadListFromLocalStorage();
        setCartItems(new_cart_list);
        setPrice(totalPrice(new_cart_list));
    };
    // console.log("price",price);
    if(!cartItems){
        return <div></div>
    }
    return (
        <div >
            <HeaderNavbar
                _content={
                <div style={{display:'flex',}}>
                    <Button
                        onClick={handleButtonClick}
                        style={{
                            fontWeight: 700,
                            fontSize: 20,
                            marginLeft:"-160px",
                            padding: 0,
                            color: "#000000",
                        }}
                    >
                        &lt;</Button>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 ,marginLeft:"120px"}}>
                        <b >{"Your Cart"}</b>
                    </Typography>
                </div>

                }
            />
            <Toolbar />
            <div style={{marginTop:'20px'}}>
                {cartItems.map((item)=>{
                    return <CartPage props={item} onAdd={onAdd} onDelete={onRemove} key={item.guid}>
                    </CartPage>
                })}
            </div>

            {/*<SubTotal*/}
            {/*    price={price}*/}
            {/*/>*/}
            <div>
                {/*<h2>Test</h2>*/}
                <Toolbar id="back-to-top-anchor" />
                <BottomNavbar
                    _leftContent={
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, fontWeight: 700, fontSize: "1rem" }}
                        >
                            Total {config().currency_symbol}{price}
                        </Typography>
                    }
                    _rightContent={
                        <PaypalComponent _amount={price.toString()} />
                    }
                />
            </div>
        </div>
    );
}
