import React from "react";
import styled from "styled-components";
import {
  InterNormalBlack20px,
  InterNormalBlack13px,
  InterBoldBlack16px,
  InterBoldBlack14px,
  ValignTextMiddle,
  InterNormalBlack8px,
} from "./styledMixins";
import "./CartPage.css";
import { useState, useEffect } from "react";
import AddAndDeleteBtn from "../AddAndDeleteBtn";

function CartPage({props,onAdd,onDelete}) {
  const {
    qty,
    guid,
    imageUrl,
    dishItemName,
    originalName,
    serveSize,
    servePrice,
  } = props;
  const item = {
    'guid':guid,
    'qty':parseInt(qty),
    'imageUrl':imageUrl,
    'dishItemName':dishItemName,
    'originalName':originalName,
    'serveSize':serveSize,
    'price':servePrice,
  }
  // console.log('dishItemName',dishItemName);
  const [items, setItems] = useState(
      JSON.parse(localStorage.getItem('cartitems')) || []
  );
  // Function to add an item to the list
  const addItem = (item) => {
    setItems([...items, item]);
    localStorage.setItem('cartitems', JSON.stringify([...items, item]));
  };


  return (
      <div >
        <div >
          <CartItemUnit>
            <Impic src={imageUrl} alt="imageUrl" />
            <FlexCol>
              <Name>
              <span>
                <span  className="inter-bold-black-12px">{dishItemName+" "}</span>
                <Span1>{originalName}</Span1>
              </span>
              </Name>
              <BolarBlade180g>
              <span>
                <span><ServeSize>{serveSize}</ServeSize></span>
              </span>
              </BolarBlade180g>
              <Price>
              <span>
                <span className="inter-bold-black-14px">${servePrice}</span>
              </span>
              </Price>

            </FlexCol>
            <FlexCol1>
              {/*<IconTrash  src={iconTrash} alt="icon-trash" onClick={()=>{*/}
              {/*  setDeleted(true);*/}
              {/*}}/>*/}
              <Qty>
              <span>
                {/*<span className="inter-bold-black-14px">{deleted ? "Qty:"+"0" : "Qty:"+qty}</span>*/}
                <AddAndDeleteBtn guid={guid} qty={parseInt(qty)} onAdd={onAdd} onDelete={onDelete}></AddAndDeleteBtn>
              </span>
              </Qty>
            </FlexCol1>
          </CartItemUnit>

        </div>

      </div>
  );
}

const CartItemUnit = styled.div`
  margin-left: 19px;
  display: flex;
  align-items: flex-start;
  min-width: 347px;
  margin-bottom: 10px;
`;
const ServeSize = styled.div`
  font-family: 'Inter'!important;
  font-style: normal!important;
  font-weight: 350!important;
  font-size: 13px!important;
  line-height: 16px!important;
  display: flex!important;
  align-items: center!important;
  color: #010000!important;

`;
const Impic = styled.img`
  border-radius: 9px;
  width: 68px;
  height: 68px;
  margin-top: 5px;
`;

const FlexCol = styled.div`
  width: 132px;
  margin-left: 18px;
  margin-top: 1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 68px;
`;

const Name = styled.div`
  ${ValignTextMiddle}
  ${InterBoldBlack16px}
            width: 200px;
  height: 22px;
  letter-spacing: 0;
  line-height: normal;
`;

const Span1 = styled.span`
  font-family: var(--font-family-inter);
  font-size: 11px;
  font-weight: 650;
  color: #904b40;
`;

const BolarBlade180g = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBlack13px}
            width: 132px;
  height: 30px;
  letter-spacing: 0;
  line-height: normal;
`;

const Qty = styled.div`
  ${ValignTextMiddle}
  ${InterBoldBlack14px}
  width: 66px;
  height: 20px;
  position: relative;
  margin-left: 41px;
  margin-top: 11px;
`;

const FlexCol1 = styled.div`
  width: 28px;
  align-self: center;
  margin-left: 88px;
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height:38px;
  gap: 25px;
`;

const IconTrash = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 7px;
  object-fit: cover;
`;

const Price = styled.div`
  ${ValignTextMiddle}
  ${InterBoldBlack14px}
            height: 17px;
  min-width: 33px;
  text-align: center;
  letter-spacing: 0.42px;
  line-height: normal;
`;



export default CartPage;
