import { useEffect } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import Config from "../Config";

function PaypalComponent({_amount}) {
  const ButtonWrapper = ({ currency }) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
      dispatch({
        type: "resetOptions",
        value: {
          ...options,
          currency: currency,
        },
      });
    }, [currency]);

    return (
      <PayPalButtons
        fundingSource="paypal"
        style={{ layout: "vertical", label: "pay", shape: "pill" }}
        disabled={false}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    value: _amount,
                  },
                },
              ],
            })
            .then((orderId) => {
              // Your code here after create the donation
              return orderId;
            });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            const name = details.status;
            alert(`Transaction completed by ${name}`);
          });
        }}
        onCancel={() => {}}
        onError={() => {}}
      />
    );
  };
  return (
    <div>
      <PayPalScriptProvider
        options={{
          "client-id": Config().client_id,
          components: "buttons",
          currency: Config().currency,
        }}
      >
        <ButtonWrapper currency={Config().currency} />
      </PayPalScriptProvider>
    </div>
  );
}

export default PaypalComponent;
