import React from "react";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useHistory } from "react-router-dom";
import "../css/MenuHeader.css"

function MenuHeader(props) {

  const history = useHistory();

  const handleCartClick = () => {
    history.push("/listofcart/" + props._sessionId);
  };

  return (
    <div className="menuRoot">
      <div className="info">
        <span>{props.restaurntName}</span>
        <span style={{ color: "#904B40", marginLeft: "8px" }}>
          Table #{props.tableId}
        </span>
      </div>
      <div style={{ position: "relative" }} onClick={handleCartClick}>
        <ShoppingCartIcon
          style={{ fontSize: "28px", float: "left", paddingTop: "4px" }}
        />
        <div
          style={{
            width: "16px",
            height: "16px",
            backgroundColor: "#904B40",
            clipPath: "circle(8px at center)",
            textAlign: "center",
            alignContent: "center",
            fontSize: "12px",
            fontWeight: 700,
            color: "white",
            position: "absolute",
            top: "0px",
            right: "-4px",
          }}
        >
          {props.cartTotal}
        </div>
      </div>
    </div>
  );
}

export default MenuHeader;
