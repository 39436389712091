import React from "react";
import styled from "styled-components";
import {InterNormalBlack20px, ValignTextMiddle} from "./styledMixins";


function SubTotal(props) {
    const {price} = props;
    let priceDigi = parseFloat(price)
    return (
        <SubTotalUnit>
          <span>
              <Aleft>
                  <span className="inter-normal-black-20px">Sub total:$</span>
                  <Span5>{priceDigi.toFixed(2)}</Span5>
              </Aleft>
          </span>
        </SubTotalUnit>
    );
}
const Aleft = styled.div`
  display: flex;
  align-items: flex-end;
`;



const SubTotalUnit = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBlack20px}

  width: 200px;
  height: 24px;
  letter-spacing: 0;
  line-height: normal;

`;

const Span5 = styled.span`
  font-family: var(--font-family-inter);

  font-weight: 700;
  color: var(--black);
  font-size: var(--font-size-xl2);
`;

export default SubTotal;
