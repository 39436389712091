import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import WelcomePage from './pages/WelcomePage';
import MenuPage from './pages/MenuPage';
import Homepage from './pages/TestPage';
import CartPagePage from "./pages/CartPagePage";
function App() {

  return (
    <>
      <Router>
        <Switch>
          <Route path="/welcome/:_sessionId?">
            <WelcomePage />
          </Route>
          <Route path="/order/:_sessionId?">
            <MenuPage />
          </Route>
          <Route path="/cart/:_sessionId?">
            <Homepage />
          </Route>
          <Route path="/listofcart/:_sessionId?">
            <CartPagePage />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;