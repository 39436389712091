import React, { useEffect, useState } from "react";
import "../App.css";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import RequestService from "../services/RequestService";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import BottomNavbar from "../components/BottomNavbar";
import PopUpWindow from "../components/PopUpWindow";
import PaypalComponent from "../components/PaypalComponent";
import { Button } from "@mui/material";

function Homepage() {
  const { _sessionId } = useParams();
  const [itemData, setItemData] = useState([]);
  const [modalEnable, setmodalEnable] = useState(false);
  /* const postData = {
    restaurantName: "Clickity",
    restaurantLocation: {
      addressLine1: "599 Swanston Street",
      addressLine2: "level 4",
      city: "Melbourne",
      state: "Victoria",
      postalCode: "3053",
      country: "Australia"
    },
    phoneNumber: "0444444444",
    profiles: [],
    menus: [],
    config: {
      tableTurnOverTime: 120,
      currentMenuGuid: "None"
    }

  }; */

  const postData = {
    tableNumber: "1",
    tableDescription: "Table of 4",
    attachedRestaurant: "1fd39af57d9b4f20885a066955fee00e",
    sessionOffset: 0
  }

  const guid = "e758ce3ac8324c6186a0c3fc797784cc";
  const keys = ["attachedRestaurant"];
  const values = ["three"];

  useEffect(() => {
    if (window.location.href.indexOf("#reloaded") == -1) {
      window.location.href = window.location.href + "#reloaded";
      window.location.reload();
    }
    /* RequestService.getItemDataByRestaurantId("Category", keys, values).then(
      (response) => {
        console.log(response);
        setItemData(response);
      }
    ); */
  }, []);

  const handleOpenClick = () => {
    setmodalEnable(true);
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    RequestService.postItemData("Table", postData).then((response) => {
      console.log(response);
      localStorage.removeItem("TableItemData");
      window.location.reload();
    });
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    RequestService.deleteItemDataByGuid("Category", guid).then(
      (response) => {
        console.log(response);
        window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(JSON.stringify(resMessage));
      }
    );
  };

  return (
    <div style={{ padding: "1rem" }}>
      <CssBaseline />
      <Toolbar />
      <p>this is header</p>
      <Box>
        {/* {[...new Array(12)]
            .map(
              () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
            )
            .join("\n")} */}
        {/* {JSON.stringify(itemData)} */}
      </Box>
      <Button onClick={handleAddClick}>add</Button>
      <Toolbar id="back-to-top-anchor" />
      <BottomNavbar _content={<PaypalComponent _amount={"159.99"} />} />
    </div>
  );
}

export default Homepage;
