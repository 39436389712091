import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import './index.css';
export default function AddAndDeleteBtn({qty,onAdd,onDelete,guid}) {
    return (
        <ButtonGroup className='btnGroup'  variant="outlined" aria-label="outlined primary button group">
            <Button  className='manipulateBtnLeft' onClick={()=>{
                onDelete(guid);
            }}>-</Button>
            <Button className='qty'>{parseInt(qty)}</Button>
            <Button  className='manipulateBtnRight' onClick={()=>{
                onAdd(guid);
            }}>+</Button>
        </ButtonGroup>
    );
}
